import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs'
import { uniqBy } from "lodash-es";

import { VisionLinkedFarmFarmClientT } from "@ruminati/types/vision_linked_farm";
import { VisionInvitationFarmClientT } from "@ruminati/types/vision_invitation";

import Screen from "../screen";
import Heading from "../../components/heading";
import { BodyText } from "../../components/styled_text";
import EmptyPlaceholder from "../../components/empty_placeholder";
import { Column, Row } from "../../components/styled_layout";
import Icon from "../../components/icon";
import { usePopupStore } from "../../state/popup_store";
import { usePartnerStore } from "../../state/partners";
import NotificationCard from "../../components/cards/notification_card";
import { useAuthStore } from "../../state/auth_store";
import { saveUserPreference } from "../../services/auth_service";
import { UserPreferences } from "../../models/api_models";
import CorporateInviteCard from "@/components/cards/corporate_invite";
import { useDatabaseStore } from "@/state/database_store";

import { RESULTS_SHARE_ALL_STATUS_KEY, RESULTS_VOUCHER_STATUS_KEY, Status as ResultStatus } from "@/guards/ShareAllVoucherGuard";
import { WarningOutlined } from "@ant-design/icons";
import { ruminatiColors } from "@/utilities/colors";
import { CardBodyText } from "../farm/farm";
import { getPendingOrAcceptedFarmLinkages } from "@/services/vision_service";

export enum CorporatePartnerPopupId {
    ConnectPartner = 'connect-corporate-partner',
    NoPartners = 'no-corporate-partners',
    ShareDetails = 'share-details',
    RemoveShareAll = 'remove-share-all',
    RemoveLinkedEnterprise = 'remove-linked-enterprise'
}

/**
 * The corporate partner screen, which shows the current linkages
 * @returns the Corporate Partner Screen component
 */
export default function CorporatePartnersScreen() {
    const partnerStore = usePartnerStore()
    const popupStore = usePopupStore()
    const authStore = useAuthStore()
    const databaseStore = useDatabaseStore()

    const [searchParams] = useSearchParams()
    const inviteIdQuery = searchParams.get("inviteId") || undefined
    const shareAllVisionOrgId = searchParams.get("shareAllVisionOrgId") ?? undefined

    const voucherResultStatusQuery = searchParams.get(RESULTS_VOUCHER_STATUS_KEY) || undefined
    const shareAllResultStatusQuery = searchParams.get(RESULTS_SHARE_ALL_STATUS_KEY) || undefined

    const invitations: VisionInvitationFarmClientT[] = partnerStore.invitations ?? []
    const uniqueOrgInvitations: VisionInvitationFarmClientT[] = uniqBy(invitations, 'invite.visionOrgId')


    const linkedFarms = partnerStore.linkedFarms ?? []
    const shareAlls = partnerStore.shareAlls ?? []
    const sortedLinkages = sortNewestToOldestFarms(linkedFarms)

    const user = authStore.user

    const [dismissedCorporatePartners, setDismissedCorporatePartners] = useState<boolean>(user?.userPreferences?.notifications?.dismissedCorporatePartners ?? false)

    useEffect(() => {
        if (inviteIdQuery &&
            popupStore.popupStack.find(p => p.id === CorporatePartnerPopupId.ConnectPartner) === undefined
        ) {
            const invite = invitations.find(i => i.invite.id === inviteIdQuery)
            if (invite) {
                popupStore.addPopup(CorporatePartnerPopupId.ConnectPartner, undefined, {
                    shareAll: true,
                    inviteId: inviteIdQuery,
                    visionOrgName: invite.visionOrganisation.name,
                    onSubmit: async () => {
                        const updatedLinkedFarms = await getPendingOrAcceptedFarmLinkages()
                        if (updatedLinkedFarms) partnerStore.setLinkedFarms(updatedLinkedFarms)
                    }
                })
            }
        }
    })

    const saveNotificationPreference = async () => {
        try {
            if (!user) return
            setDismissedCorporatePartners(true)
            const modifiedUserPreferences: UserPreferences = {
                ...user.userPreferences,
                notifications: {
                    ...user.userPreferences?.notifications,
                    dismissedCorporatePartners: true
                }
            }
            const response = await saveUserPreference(modifiedUserPreferences);
            if (response) authStore.signIn(response)
        } catch (err) {
            console.log(err)
        }
    };

    const newShareAllPartner = partnerStore.invitations?.find(i => i.invite.visionOrgId === shareAllVisionOrgId)?.visionOrganisation.name
    const voucherSuccessful = voucherResultStatusQuery === ResultStatus.Success

    return (
        <Screen pageTitle="Data Sharing">
            <CorporatePartnerWrapper>
                <CorporatePartnerContainer>
                    {(shareAllResultStatusQuery === ResultStatus.Failed || voucherResultStatusQuery === ResultStatus.Failed) &&
                        <Row style={{marginBottom: '20px'}}>
                            <NotificationCard
                                type="warning"
                                title={"Error Encountered"}
                                contentArea={<>
                                <WarningOutlined style={{color: ruminatiColors.orange_dark}}/>
                                <BodyText style={{
                                    paddingLeft: '10px',
                                    color: ruminatiColors.orange_dark
                                }}>
                                    {shareAllResultStatusQuery === ResultStatus.Failed ? 'Something went wrong attempting to perform an automated Share All of your data. ' : ''}
                                    {voucherResultStatusQuery === ResultStatus.Failed ? `Something ${shareAllResultStatusQuery ? 'also' : ''} went wrong attempting to automatically upgrade your account to Ruminati PRIME.` : ''}
                                    The Ruminati Team has been notified.
                                </BodyText>
                                </>}
                            />
                        </Row>
                    }
                    {shareAllResultStatusQuery === ResultStatus.Success &&
                        <Row style={{marginBottom: '20px'}}>
                            <NotificationCard
                                type="success"
                                title={`Sharing with ${newShareAllPartner} enabled ${voucherSuccessful ? ' & Ruminati PRIME Upgrade complete ' : ''}`}
                                contentArea={<Row style={{alignItems: 'flex-start'}}>
                                    <Column>
                                        🎉
                                    </Column>
                                    <Column>
                                        <CardBodyText style={{
                                            paddingLeft: '20px',
                                            textAlign: 'left',
                                            color: ruminatiColors.dark_green
                                        }}>
                                            You are now sharing your emissions report data with {newShareAllPartner},
                                            if required this can be disabled below{voucherSuccessful ? ' once the mandatory sharing period has finished': ''}.
                                            {voucherSuccessful && newShareAllPartner ? ` ${newShareAllPartner} have also granted your account an upgrade to Ruminati PRIME which has been automatically applied.` : ''}
                                        </CardBodyText>
                                    </Column>
                                </Row>}
                            />
                        </Row>
                    }

                    {dismissedCorporatePartners === false &&
                        <Row style={{marginBottom: '20px'}}>
                            <NotificationCard
                                title={"Data Sharing with Corporate Partners"}
                                description={"Ruminati lets you share Emissions Report results, as well as Reduction Plans, from each of your enterprises with Corporate Partners. To get started, a Corporate Partner will need to send a request before you are able to share your data with them."}
                                contentArea={
                                    <Row style={{justifyContent: 'flex-start', width: '100%'}}>
                                        <Icon icon="info-circle" />
                                        <BodyText style={{paddingLeft: '10px'}}>
                                            A Corporate Partner will need to approve your data sharing request before data will be shared between both parties.
                                        </BodyText>
                                    </Row>}
                                onClick={saveNotificationPreference}
                            />
                        </Row>
                    }
                    <Row style={{ marginBottom: "20px", justifyContent: 'left' }}>
                        <Column style={{ alignItems: "start" }}>
                            <Heading level={3}>Data Sharing</Heading>
                        </Column>
                    </Row>

                    <Row style={{ marginBottom: "80px" }}>
                        <Column style={{ width: "100%" }}>
                            {invitations.length === 0 ?
                                <EmptyPlaceholder>
                                    No Corporate Partners have requested your data yet.
                                </EmptyPlaceholder>
                                :
                                uniqueOrgInvitations.map(invitation => {
                                    const existingOrgLinkedEnterprises = sortedLinkages.filter(l => l.linkedFarm.visionOrgId === invitation.invite.visionOrgId)
                                    const orgShareAll = shareAlls.find(share => share.visionOrgId === invitation.invite.visionOrgId)
                                    const allOrgInvites = invitations.filter(i => i.invite.visionOrgId === invitation.invite.visionOrgId)
                                    return <CorporateInviteCard
                                        key={invitation.invite.id}
                                        invites={allOrgInvites}
                                        linkedEnterprises={existingOrgLinkedEnterprises}
                                        shareAll={orgShareAll}
                                        propertyCount={databaseStore.properties?.length ?? 0}
                                        showInviteDate={true}
                                    />
                                })
                            }
                        </Column>
                    </Row>
                </CorporatePartnerContainer>
            </CorporatePartnerWrapper>
        </Screen>
    );
}

function sortNewestToOldestFarms (unsortedLinkedFarms: VisionLinkedFarmFarmClientT[]): VisionLinkedFarmFarmClientT[] {
    return unsortedLinkedFarms.sort((a, b) => {
        if (dayjs(a.linkedFarm.partnershipRequestSent).isBefore(dayjs(b.linkedFarm.partnershipRequestSent))) return 1
        return -1
    });
}

const CorporatePartnerWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;

    width: 100%;
    flex-grow: 1;

    padding-top: 60px;
`;

const CorporatePartnerContainer = styled.div`
    width: 100%;
    max-width: 936px;
    text-align: left;
`;
