import { googleLogout } from "@react-oauth/google";
import posthog from "posthog-js";

import { AuthDTOT, RegisterDTOT } from '@ruminati/types/auth'
import { type UserClientT, type UserDTOT } from "@ruminati/types/user";

import { UserPreferences, UserProfileDTO } from "../models/api_models";
import { UpsertSubscriptionUsingVoucherDTO } from "../models/auth_models";
import { useAuthStore } from "../state/auth_store";
import { useDatabaseStore } from "../state/database_store";
import { post, baseUrl, get, patch } from "./api_service";
import { usePartnerStore } from "../state/partners";
import { CountryCode } from "../utilities/countries";
import queryClient from "@/utilities/queryClient";
import { clearCookie, CURRENT_USER_ID_COOKIE_KEY, getCookies } from "@/utilities/cookies";

import { CURRENT_ORGANISATION_ID_COOKIE_KEY } from "@/utilities/organisations"

export const tokenKey = "ruminatiToken";
export const refreshTokenKey = "ruminatiRefreshToken";
export const userKey = "ruminatiUser";
export const verifiedKey = "ruminatiVerified";

export const baseAuthUrl = `${baseUrl}/auth`;

/**
 * Logs in an existing user
 * @param email the user's email
 * @param password the user's password
 * @returns a promise resolving with the user on success
 */
export async function loginWithEmailPassword(
  email: string,
  password: string,
  remember: boolean
): Promise<UserClientT | undefined> {
  const authDTO: AuthDTOT = await post(`${baseAuthUrl}/login`, { email, password });

  const user = handleAuthResponse(authDTO, remember);
  return user;
}

/**
 * Creates a new user and logs them in
 * @param user the details of the user (incl. email, name and company)
 * @param password the new user's password
 * @returns a promise resolving with the user on success
 */
export async function signUpEmailPassword(data: RegisterDTOT): Promise<UserClientT | undefined> {
  const authDTO: AuthDTOT = await post(`${baseAuthUrl}/register`, data);

  const user = handleAuthResponse(authDTO, true);

  if (user && user.email) {
    sendVerificationEmail(user.email);
  }

  return user;
}

/**
 * Signs in a user on the backend with the
 * @param code the google provider token
 * @param signupCode A partner signup code, only used during initiating a new account
 * @returns a promise resolving with the user on success
 */
export async function signInWithGoogle(code: string, otherInfo: {
    shareAllVisionOrgId?: string
    voucherPoolId?: string
    promoCode?: string
    partnerReferenceId?: string
    relationshipInvitationId?: string
} | undefined): Promise<UserClientT | undefined> {
    const response = await post(`${baseAuthUrl}/signInWithProvider`, {
        provider: "google.com",
        code: code,
        redirectUri: window.location.origin,
        shareAllVisionOrgId: otherInfo?.shareAllVisionOrgId,
        voucherPoolId: otherInfo?.voucherPoolId,
        promoCode: otherInfo?.promoCode,
        visionOrgClientReferenceId: otherInfo?.partnerReferenceId,
        relationshipInvitationId: otherInfo?.relationshipInvitationId
    });

    const user = handleAuthResponse(response, true);

    return user;
}

/**
 * Signs in a user on the backend with the
 * @param code the google provider token
 * @param signupCode A partner signup code, only used during initiating a new account
 * @returns a promise resolving with the user on success
 */
export async function signInWithMicrosoft(loginDetails: {
    uid: string,
    email: string,
    firstName: string,
    lastName: string,
    accessToken: string,
    refreshToken: string,
    expiresIn: number
}, otherInfo: {
    shareAllVisionOrgId?: string
    voucherPoolId?: string
    promoCode?: string
    partnerReferenceId?: string
    relationshipInvitationId?: string
} | undefined): Promise<UserClientT | undefined> {
    const response = await post(`${baseAuthUrl}/signin_microsoft_user`, {
        ...loginDetails,
        ...otherInfo,
        visionOrgClientReferenceId: otherInfo?.partnerReferenceId
    });

    const user = handleAuthResponse(response, true);

    return user;
}

/**
 * Sends a verification email to the user
 * @param email the email of the user to verify
 * @returns a promise resolving with the success of the operation
 */
export async function sendVerificationEmail(email: string): Promise<boolean> {
    const data = await post(
        `${baseAuthUrl}/verifyEmail`,
        { email },
        getTokens().token ?? undefined
    );
    return !!data;
}

/**
 * Get user profile.
 * @returns a promise resolving with the logged in user's profile information
 */
export async function getUserProfile(): Promise<UserClientT | undefined> {
  const userDTO: UserDTOT = await get(`${baseAuthUrl}/userProfile`, getTokens().token);

  const user = saveUser(userDTO);
  return user;
}

/**
 * Add user's country to their profile
 * @param country the country of the user
 * @returns a promise resolving with the success of the operation
 */
export async function addUserCountry(country: CountryCode): Promise<UserClientT | undefined> {
  const userDTO: UserDTOT = await patch(
      `${baseUrl}/user`,
      { country },
      getTokens().token ?? undefined
  );
  const user = saveUser(userDTO);
  return user;
}

export async function patchUserProfile(partialUserProfile: Partial<UserProfileDTO & {auctionsPlusStatusRetrievalApproved: boolean}>): Promise<UserClientT | undefined> {
  const userDTO: UserDTOT = await patch(
      `${baseUrl}/user`,
      { ...partialUserProfile },
      getTokens().token ?? undefined
  );
  const user = saveUser(userDTO);
  return user;
}

export async function setUserEmailVerified(): Promise<UserClientT | undefined> {
  const userDTO: UserDTOT = await patch(
      `${baseUrl}/user`,
      { emailVerified: true },
      getTokens().token ?? undefined
  );
  const user = saveUser(userDTO);
  return user;
}

/**
 * Sends a forgot password email to the user
 * @param email the email for the account the user forgot the password to
 * @returns a promise resolving with the success of the operation
 */
export async function sendForgotPassword(email: string): Promise<boolean> {
    const data = await post(`${baseAuthUrl}/resetPassword`, { email });

    return !!data;
}



export async function saveUserPreference(newUserPreferences: Partial<UserPreferences>): Promise<UserClientT | undefined>{
  try {
    const userDTO: UserDTOT = await patch(
      `${baseUrl}/user`,
      { userPreferences: newUserPreferences },
      getTokens().token ?? undefined
    );
    const user = saveUser(userDTO);
    return user;
  } catch (exception) {
    console.log(exception);
  }
}

export async function upsertSubscriptionUsingVoucher(voucherConfig: UpsertSubscriptionUsingVoucherDTO): Promise<UserClientT | undefined | Error>{
  try {
    const cookies = getCookies()
    const currentOrgId = cookies[CURRENT_ORGANISATION_ID_COOKIE_KEY]
    const userDTO: UserDTOT = await post(
      `${baseUrl}/organisations/${currentOrgId}/upsert_subscription_using_voucher`,
      voucherConfig,
      getTokens().token ?? undefined
    )
    const user = saveUser(userDTO);
    return user;
  } catch (error) {
    console.log(error);
    return error as Error
  }
}

/**
 * Uses the refresh token to get a new token
 * @returns a promise resolving with the user's ID on success
 */
export async function refreshToken(
    remember: boolean
): Promise<string | undefined> {
    const refreshToken = getTokens().refreshToken;

    if (refreshToken !== null) {
        const response = await post(`${baseAuthUrl}/refresh`, {
            token: refreshToken,
        });
        handleAuthResponse(response, remember);

        // Return refreshed token
        if ("token" in response) {
            return response.token;
        }
    }

    // Default to undefined
    return undefined;
}

/**
 * Handles the tokens and conversion of an auth response
 * @param data JSON data returned as the response
 * @param remember whether to remember the data between sessions
 * @returns the converted auth response dto, or null if the data was invalid
 */
export function handleAuthResponse(data: AuthDTOT, remember: boolean): UserClientT | undefined {
    const user = saveUser(data.profile, !remember)
    if (!remember) {
        sessionStorage.setItem(tokenKey, data.token);
        sessionStorage.setItem(refreshTokenKey, data.refreshToken);
        sessionStorage.setItem(userKey, JSON.stringify(user));
    } else {
        localStorage.setItem(tokenKey, data.token);
        localStorage.setItem(refreshTokenKey, data.refreshToken);
    }
    return user
}

/**
 * Retrieves the tokens from storage
 * @returns the two tokens
 */
export function getTokens(): { token?: string; refreshToken?: string } {
    return {
        token: getFromStorage(tokenKey),
        refreshToken: getFromStorage(refreshTokenKey),
    };
}

/**
 * Retrieves a single property from storage, trying session and then local
 * @param key the key of the property
 * @returns the property or null if not given
 */
export function getFromStorage(key: string): string | undefined {
    if (sessionStorage.getItem(key) != null) {
        return sessionStorage.getItem(key) ?? undefined;
    }

    return localStorage.getItem(key) ?? undefined;
}

/**
 * Logs out a user by clearing the session storage (containing tokens)
 */
export function logOut() {
    googleLogout();
    useAuthStore.getState().logOut();
    usePartnerStore.getState().clear();
    useDatabaseStore.getState().clear();

    localStorage.clear();
    sessionStorage.clear();

    // clear currentOrg cookie
    clearCookie(CURRENT_ORGANISATION_ID_COOKIE_KEY)
    clearCookie(CURRENT_USER_ID_COOKIE_KEY)

    queryClient.clear()

    posthog.reset()
}


// Utils

/**
 * Save user to local storage
 */
function saveUser(userProfile: UserDTOT | null, preventSaveToLS = false): UserClientT | undefined {
  if (userProfile == null) return;
  const user: UserClientT = {
    uid: userProfile.id,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    email: userProfile.email,
    company: userProfile.company,
    emailVerified: userProfile.emailVerified,
    country: userProfile.country,
    timestamp: Date.now(),
    userPreferences: userProfile.userPreferences,
    organisations: userProfile.organisations,
    permissions: userProfile.permissions,
    relationships: userProfile.relationships
  };
  if (preventSaveToLS === false)
    localStorage.setItem(userKey, JSON.stringify(user));
  return user;
}
