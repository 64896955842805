import { USER_ID_COOKIE_FOR_ENV } from "@ruminati/constants"
import { getEnvFromUrl } from "./env"

const cookieEnv = getEnvFromUrl()
export const CURRENT_USER_ID_COOKIE_KEY = USER_ID_COOKIE_FOR_ENV(cookieEnv)

export function getCookies (): Record<string, any> {
  return document.cookie.split(';').reduce((acc, c) => {
    const kv = c.trim().split('=')
    return {
      ...acc,
      [kv[0]]: kv[1]
    }
  }, {})
}

export function setCookie (name: string, value: string) {
  return document.cookie = `${name}=${value};path=/;${getDomainForCookie()}`
}

export function clearCookie (name: string) {
  return document.cookie = `${name}="";expires=-1;${getDomainForCookie()}`
}

function getDomainForCookie (): string {
  const env = getEnvFromUrl()
  const domain = env === 'local' ? 'localhost' : '.ruminati.com.au'
  return `domain=${domain}`
}
