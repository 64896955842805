import { AgriWebbFarm } from "@/models/agriwebb"
import { getAgriWebbFarms } from "@/services/agriwebb_service"
import { useIntegrationsStore } from "@/state/integrations"
import { agriwebbErrorNotification } from "@/utilities/notification-defaults"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

export function useAgriwebbFarms() {
  const integrationsStore = useIntegrationsStore()
  const navigate = useNavigate()

  function callback () {
    navigate('/account?tab=integrations&agriwebb_error=true')
  }

  return useQuery({
    queryKey: ['agriwebb_farms'],
    queryFn: () => new Promise<Array<AgriWebbFarm>>(async (resolve, reject) => {
      if (!integrationsStore.integrations?.agriwebb) {
        return resolve([])
      }
      getAgriWebbFarms()
      .then((result) => {
        if (result) integrationsStore.setAgriWebbFarms(result)
        else integrationsStore.setAgriWebbFarms([])
        resolve(result ?? [])
      }).catch(err => {
        if (!integrationsStore.agriwebbErrorNotificationAlreadyDisplayed) {
          agriwebbErrorNotification(callback)
          integrationsStore.setAgriwebbErrorNotificationAlreadyDisplayed(true)
        }
        reject(err)
      })
    }),
    retry: (_retryAttempt, error) => {
      if (error.cause === 'Could not get valid AgriWebb token') return false
      return true
    }
  })
}